import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faA, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import MapContainer from '../commons/maps/MapContainer';
import "./Geofence.css";
import { getDevices } from '../../redux/actions/devices';
import { Button, Checkbox, Dropdown, Input, notification } from 'antd';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { createGeofence, deleteGeofence, getGeofences, updateGeofence } from '../../services/axios';
import { showConfirmEx, truncate } from '../../utils/globals';

const getPlateNoDesc = (checkedImeis, checkedAll, devices) => {
  if (checkedImeis.length == 0) {
    return "Plate No.";
  }
  if (checkedAll) {
    return "All Vehicles";
  }
  let names = devices.filter(item => checkedImeis.includes(item.deviceImei)).map(item => item.vehicleName);
  return truncate(names.join(", "), 20);
}

const Geofance = () => {

  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devicesList.devices);
  const [checkedImeis, setCheckedImeis] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [geofenceDropdownOpen, setGeofenceDropdownOpen] = useState(false);

  const [geofences, setGeofences] = useState([]);
  const [selectedGeoitem, setSelectedGeoitem] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newGeoitemName, setNewGeoitemName] = useState("");

  const [drawingType, setDrawingType] = useState("none");
  const [drawingGeometry, setDrawingGeometry] = useState(null);

  const getList = async () => {
    const res = await getGeofences();
    if (res?.status == 200) {
      setGeofences(res.data.result);
    }
  }

  useEffect(async () => {
    dispatch(getDevices(false));
    getList();
  }, []);

  useEffect(() => {
    let allChecked = true;
    for (const device of devices) {
      if (!checkedImeis?.find(imei => imei == device.deviceImei)) {
        allChecked = false;
        break;
      }
    }
    setCheckedAll(allChecked);
  }, [checkedImeis, devices]);

  useEffect(() => {
    if (!geofenceDropdownOpen) {
      setIsCreatingNew(false);
      setNewGeoitemName("");
    }
  }, [geofenceDropdownOpen]);

  useEffect(() => {
    if (selectedGeoitem) {
      setCheckedImeis(selectedGeoitem.deviceImeis);
      // setDrawingType(selectedGeoitem.type);
      setDrawingType("none");
      setDrawingGeometry({
        type: selectedGeoitem.type,
        geometry: selectedGeoitem.geometry
      });
    } else {
      setCheckedImeis([]);
      setDrawingType("none");
      setDrawingGeometry(null);
    }
  }, [selectedGeoitem]);

  const dropdownRef = useOutsideClick(() => {
    setGeofenceDropdownOpen(false);
  });

  const handleCheck = (checked, item) => {
    const checkedDevices = [...checkedImeis];
    const index = checkedDevices.findIndex(d => d == item.deviceImei);
    if (index != -1) {
      checkedDevices.splice(index, 1);
    }
    if (checked) {
      checkedDevices.push(item.deviceImei);
    }
    setCheckedImeis(checkedDevices);
  }

  const handleCheckAll = (checked) => {
    if (checked) {
      setCheckedImeis(devices?.map(item => item.deviceImei));
    } else {
      setCheckedImeis([]);
    }
    setCheckedAll(checked);
  }

  const handleCreate = async () => {
    if (newGeoitemName.trim().length == 0) {
      notification.error({
        description: "Please input the zone name",
      });
      return;
    }
    const res = await createGeofence(newGeoitemName, [], drawingType, null);
    if (res.status === 200) {
      setGeofences([
        res.data.result,
        ...geofences
      ]);
      setSelectedGeoitem(res.data.result);
      setGeofenceDropdownOpen(false);
    } else {
      notification.error({
        description: "Error occured!",
      });
    }
  }

  const handleSave = async () => {
    if (selectedGeoitem == null) {
      return;
    }
    const res = await updateGeofence(selectedGeoitem._id, selectedGeoitem.name, checkedImeis, drawingGeometry.type, drawingGeometry.geometry);
    if (res.status == 200) {
      setGeofences(geofences.map(item => {
        if (item._id == selectedGeoitem._id) {
          return {
            ...item,
            deviceImeis: checkedImeis,
            type: drawingGeometry.type,
            geometry: drawingGeometry.geometry,
          }
        } else {
          return item;
        }
      }));

      notification.success({
        description: "Zone has been updated successfully!",
      });
    } else {
      notification.error({
        description: "Error occured!",
      });
    }
  }
  const handleDelete = async () => {
    if (selectedGeoitem == null) {
      return;
    }
    showConfirmEx(`Are You sure to Delete the ${selectedGeoitem.name}`)
      .then(async () => {
        const res = await deleteGeofence(selectedGeoitem._id);
        if (res.status == 200) {
          setGeofences(geofences.filter(item => item._id != selectedGeoitem._id))
          setSelectedGeoitem(null);

          notification.success({
            description: "Zone has been removed successfully!",
          });
        } else {
          notification.error({
            description: "Error occured!",
          });
        }
      }).catch(() => {
      });
  }

  const handleChangeDrawingType = async (type) => {
    if (drawingType == type) {
      setDrawingType("none");
    } else {
      setDrawingType(type);
    }
  }

  const handleUpdateGeometry = async (type, geometry) => {
    console.log(type, geometry);
    setDrawingGeometry({ type, geometry });
  }

  return (
    <div className="map-container position-relative w-100 geofence-main">
      <div className="w-100 d-flex flex-column flex-sm-row justify-content-between align-items-center geofence-top-panel-wrapper">
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center bg-white px-5 py-3 mt-5 mt-sm-0 sub1-geofence-div1">
          <div className="d-flex flex-column justify-content-center align-items-center gap-3" id="dropdownRef" ref={dropdownRef}>
            <label>Select or Create Zone</label>
            <Dropdown
              open={geofenceDropdownOpen}
              getPopupContainer={() => document.getElementById('dropdownRef')}
              trigger={"click"}
              placement="bottom"
              dropdownRender={(menu) => {
                return (
                  <div className="d-flex flex-column bg-white px-3 py-3 geofence-dropdown-menu">
                    {isCreatingNew ?
                      <Input placeholder='New Geozone name'
                        value={newGeoitemName}
                        onChange={(e) => setNewGeoitemName(e.target.value)}
                        onPressEnter={handleCreate}
                      /> :
                      <div className='cursor-pointer px-3 py-2 text-primary' onClick={() => setIsCreatingNew(true)}>Create</div>
                    }
                    {geofences.map((item, index) => (
                      <div key={index} className='cursor-pointer px-3 py-2'
                        onClick={(e) => {
                          setSelectedGeoitem(item);
                          setGeofenceDropdownOpen(false);
                        }}>
                        {item.name}
                      </div>
                    ))}
                  </div>
                );
              }}
            >
              <Button onClick={() => setGeofenceDropdownOpen(!geofenceDropdownOpen)} className='d-flex align-items-center justify-content-center position-relative'>
                <span style={{ color: selectedGeoitem == null ? "#c0c0c0" : "black" }}>
                  {selectedGeoitem == null ? "Geozone" : selectedGeoitem.name}
                </span>
                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
              </Button>
            </Dropdown>
          </div>
          <div className="mx-5 divider d-none d-sm-block"></div>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3 position-relative">
            <label>Select Vehicle to Add to Zone</label>
            <Dropdown
              trigger={"click"}
              placement="bottom"
              dropdownRender={(menu) => {
                return (
                  <div className="d-flex flex-column bg-white px-3 py-3 geofence-dropdown-menu">
                    <Checkbox className='fw-bold px-3 py-2 gap-3' style={{ color: "#D01400" }}
                      checked={checkedAll}
                      onChange={(e) => handleCheckAll(e.target.checked)}>
                      All Vehicles
                    </Checkbox>
                    {devices.map((item, index) => (
                      <Checkbox key={index} className='px-3 py-2 gap-3'
                        checked={checkedImeis.includes(item?.deviceImei)}
                        onChange={(e) => handleCheck(e.target.checked, item)}>
                        {item.vehicleName}
                      </Checkbox>
                    ))}
                  </div>
                );
              }}
            >
              <Button className='d-flex align-items-center justify-content-center position-relative'>
                <span style={{ color: checkedImeis.length == 0 ? "#c0c0c0" : "black" }}>
                  {getPlateNoDesc(checkedImeis, checkedAll, devices)}
                </span>
                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
              </Button>
            </Dropdown>
          </div>
        </div>

        <div className="d-flex flex-row flex-sm-column mt-3 mt-sm-0 gap-3">
          <div className="tab-button d-flex justify-content-center align-items-center gap-3" onClick={handleSave}>
            <img src="/assets/Save.svg" alt="none" />
            <button>Save</button>
          </div>
          <div className="tab-button d-flex justify-content-center align-items-center gap-2" onClick={handleDelete}>
            <img src="/assets/delete.svg" alt="none" />
            <button>Delete</button>
          </div>
        </div>
      </div>

      {selectedGeoitem != null &&
        <div className='position-absolute left-0 ms-5 d-flex flex-column justify-content-center gap-5 geofence-drawings-panel'>
          <div className={`${drawingType == 'circle' && 'selected'}`}
            onClick={() => handleChangeDrawingType("circle")}
          >
            <img src="/assets/img1.png" alt="none" />
          </div>
          <div className={`${drawingType == 'rectangle' && 'selected'}`}
            onClick={() => handleChangeDrawingType("rectangle")}
          >
            <img src="/assets/img2.png" alt="none" />
          </div>
          <div className={`${drawingType == 'polygon' && 'selected'}`}
            onClick={() => handleChangeDrawingType("polygon")}
          >
            <img src="/assets/img3.png" alt="none" />
          </div>
        </div>
      }

      <MapContainer isDrawing={true} drawingType={drawingType} geofences={geofences} selectedGeoitem={selectedGeoitem} onUpdateGeometry={handleUpdateGeometry} />
    </div>
  );
};

export default Geofance;
