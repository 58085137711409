import React, { useEffect, useState } from 'react'
import { Checkbox, Input, Modal, notification } from 'antd';
import { updateRule } from '../../../services/axios';
import { RuleTypes } from '../../../common/common';

const _initValues = {
    name: "",
    type: "",
    alertText: "",
    isTeltonika: true,
    isRuptela: false,
    alertNotification: true,
    alertEmail: false,
    alertSMS: false,
    threshold: "",
};

const placeholders = {
    [RuleTypes.OverSpeed]: "Speed Limit",
    [RuleTypes.FuelLow]: "Fuel Low Limit",
    [RuleTypes.TemperatureLow]: "Temperature Low Limit",
    [RuleTypes.TemperatureHigh]: "Temperature High Limit",
    [RuleTypes.BatteryLow]: "Battery Low Limit",
}

const BuiltinRuleEdit = ({ isShowModal, onClose, rule }) => {

    const [values, setValues] = useState(_initValues);

    useEffect(() => {
        if (rule) {
            setValues(rule);
        }
    }, [rule]);

    const handleValue = (field, value) => {
        setValues(prev => ({
            ...prev,
            [field]: value
        }));
    }

    const handleSubmit = async () => {
        let msg = null;
        if (values.name.length == 0) {
            msg = "Please input the Rule name";
        }
        if (msg != null) {
            notification.error({
                description: msg,
            });
            return;
        }

        let res = await updateRule(rule._id, values);
        if (res.status == 200) {
            notification.success({
                description: "Updated successfully!",
            });
            onClose(true);
        } else {
            notification.error({
                description: "Something went wrong!",
            });
        }
    }

    return (
        <Modal
            title={(
                <div className='position-relative text-center'>
                    <span className="fw-normal text-danger position-absolute top-0 start-0 mt-2 ms-4" style={{ textDecoration: "underline" }}>
                        Edit Rule
                    </span>
                    <span className='bg-danger text-white rounded-3 px-4 py-2' style={{ fontSize: "1.2rem" }}>{rule?.type}</span>
                </div>
            )}
            centered
            open={isShowModal}
            onOk={() => onClose()}
            onCancel={() => onClose()}
            width={600}
            style={{ maxWidth: "100%" }}
            footer={false}
        >
            <div className='pt-4 rule-edit-modal'>
                <div className='row mb-5'>
                    <div className='col-6'>
                        <Input
                            className='text-center'
                            placeholder='Rule Name'
                            size='large'
                            value={values.name}
                            onChange={(e) => handleValue('name', e.target.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <Input
                            className='text-center'
                            placeholder='Alert Text'
                            size='large'
                            value={values.alertText}
                            onChange={(e) => handleValue('alertText', e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-6'>
                        {placeholders[values.type] &&
                            <Input
                                className='text-center'
                                placeholder={placeholders[values.type]}
                                size='large'
                                value={values.threshold}
                                onChange={(e) => handleValue('threshold', e.target.value)}
                            />
                        }
                    </div>
                    <div className='col-6'>
                        <div className='rounded-4 border border-light-subtle d-flex flex-column gap-3 py-3 px-4'>
                            <div className=''>Alert Notifications:</div>
                            <Checkbox
                                checked={values.alertNotification}
                                className='ms-3'
                                onChange={(e) => handleValue('alertNotification', e.target.checked)}
                            >
                                <span className='ms-4'>Notification</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertEmail}
                                className='ms-3'
                                onChange={(e) => handleValue('alertEmail', e.target.checked)}
                            >
                                <span className='ms-4'>Email</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertSMS}
                                className='ms-3'
                                onChange={(e) => handleValue('alertSMS', e.target.checked)}
                            >
                                <span className='ms-4'>SMS</span>
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <button className="tab-button text-white" onClick={() => onClose()}>Cancel</button>
                    <button className="tab-button text-white" onClick={() => handleSubmit()}>Save</button>
                </div>
            </div>
        </Modal>
    )
}

export default BuiltinRuleEdit;
