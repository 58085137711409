import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { commandSetting, getCompanies } from "../../../services/axios";
import {
  SWITCH_MENU_VISIBLE,
  SWITCH_SIDEBAR_VISIBLE,
  UPDATE_DEVICE_STATUS,
} from "../../../redux/store/types";
import { getDevices } from "../../../redux/actions/devices";

import "./SideBar.css";
import { formatDateTime, getDeviceImage, getGooglePosUrl, showConfirmEx, sleep } from "../../../utils/globals";
import { Popover, Select, message } from "antd";
import { cmdType } from "../../../common/common";
import { sendAddDevicesMsg, sendCommandMsg, socket } from "../../../socket";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const connectStatus = [
  {
    id: 1,
    label: "Online",
    value: "Connected",
    icon: "/assets/Online.svg",
    color: "#28A745",
  },
  {
    id: 2,
    label: "Offline",
    value: "Not Connected",
    icon: "/assets/Offline.svg",
    color: "#D01400",
  },
  {
    id: 3,
    label: "Idle",
    value: "Idle",
    icon: "/assets/Idle.svg",
    color: "#F89823",
  },
];

const vehicleTypes = [
  {
    id: 1,
    label: "Car",
    icon: "/assets/Car.svg",
  },
  {
    id: 2,
    label: "Bike",
    icon: "/assets/Bike.svg",
  },
];

let engineToggling = false;

const SideBar = (props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const devices = useSelector((state) => state.devicesList.devices);
  const showMenu = useSelector((state) => state.global.showMenu);
  const showSidebar = useSelector((state) => state.global.showSidebar);

  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [simState, setSimState] = useState(false);
  const [boardColor, setBoardColor] = useState(
    ".1rem solid rgb(158, 154, 154, 50%)"
  );
  const [uploadImg, setUploadImg] = useState("/assets/Pic11.svg");
  const [uploadText, setUploadText] = useState("Upload Config");
  const [searchText, setSearchText] = useState("");
  const [isUploadDialogShow, setIsUploadDialogShow] = useState(false);
  const [connectedTag, setConnectedTag] = useState("");
  const [vehicleTypeTag, setVehicleTypeTag] = useState("");
  const [selectedVeh, setSelectedVeh] = useState(null);
  const [selectedVehData, setSelectedVehData] = useState(null);

  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);

  const modalRef = useOutsideClick(() => {
    if (!engineToggling) {
      setSelectedVeh(null);
    }
  });

  useEffect(async () => {
    if (isAuthenticated) {
      engineToggling = false;

      socket.disconnect().connect();

      getCompanies()
        .then(res => {
          if (res.status == 200) {
            setOwners(res.data.result.map(item => ({ value: item._id, label: item.owner })));
          }
        })
        .catch(err => { });

      dispatch(getDevices(false));
      await sleep(100);
      dispatch(getDevices(true));
    }

  }, [isAuthenticated]);

  useEffect(() => {
    setSelectedVehData(
      devices?.filter((d) => d.deviceImei === selectedVeh)[0]
    );
  }, [selectedVeh]);

  useEffect(() => {
    if (devices?.length > 0) {
      var result = devices?.filter((item, index) =>
        (!connectedTag || item?.status === connectedTag) &&
        (!vehicleTypeTag || item?.vehicleType === vehicleTypeTag)
      );
      if (searchText != "") {
        result = result.filter(item => (item.vehicleName?.toLowerCase().indexOf(searchText.toLowerCase()) != -1 || item.deviceImei?.indexOf(searchText) != -1));
      }
      if (selectedOwner) {
        result = result.filter(item => (item.company == selectedOwner));
      }
      setFilteredVehicles(result);

      if (selectedVeh) {
        setSelectedVehData(
          devices?.filter((d) => d.deviceImei === selectedVeh)[0]
        );
      }

      // when gsmNumbers updated
      if (location.pathname == "/CommandsSettings") {
        const updatedDevice = devices?.find((d) => d.deviceImei === location.state.deviceImei);
        if (updatedDevice) {
          if (JSON.stringify(location.state.gsmNumbers) != JSON.stringify(updatedDevice.gsmNumbers)) {
            location.state.gsmNumbers = updatedDevice.gsmNumbers;
          }
        }
      }
    } else {
      setFilteredVehicles([]);
    }
  }, [connectedTag, vehicleTypeTag, devices, searchText, selectedOwner]);

  const handleShowInfo = (idx, e) => {
    e.stopPropagation();
  }

  const handleLocation = (id, e) => {
    e.stopPropagation();

    var lat = devices?.at(id)?.lat;
    var lng = devices?.at(id)?.lng;
    navigate("/Dashboard", {
      state: {
        point: { lat, lng }
      }
    });

    hideMobileMenu();
  };

  const handleNavigation = (id, e) => {
    e.stopPropagation();

    var lat = devices?.at(id)?.lat;
    var lng = devices?.at(id)?.lng;

    window.open(getGooglePosUrl(lat, lng), "_blank");
  };

  const handleEngine = (e, imei, name) => {
    let state = e.target.checked;
    engineToggling = true;
    showConfirmEx(`Are you sure to Turn ${state ? 'ON' : 'OFF'} Engine for ${name}?`)
      .then(async () => {
        engineToggling = false;
        setLoadingMsg("Toggling Engine status ...");
        setIsLoading(true);

        const sendData = {
          token: localStorage.getItem("token"),
          type: cmdType.EngineOn,
          params: { on: state ? "1" : "0" },
          devImei: imei,
        };
        const res = await commandSetting(sendData);
        if (res.status === 200) {
          sendCommandMsg(res.data.commandId);
          await sleep(10000);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }).catch(() => {
        engineToggling = false;
      });
  };

  const handlUpload = () => {
    setUploadImg("/assets/Pic12.svg");
    setUploadText("Update In Progres");
    setIsUploadDialogShow(!isUploadDialogShow);
    setTimeout(() => {
      setUploadImg("/assets/Pic11.svg");
      setUploadText("Upload Config");
    }, 2000);
  };

  const handleColor = (status) => {
    if (status === "Connected" || status === "Idle") {
      return "#28A745";
    } else if (status === "Not Connected") {
      return "#FF0000";
    } else if (status === "Idle") {
      return "#F89823";
    }
  };

  const handleTagClick = (value, type) => {
    switch (type) {
      case 0:
        setConnectedTag(value === connectedTag ? "" : value);
        break;
      case 1:
        setVehicleTypeTag(value === vehicleTypeTag ? "" : value);
        break;
      default:
        break;
    }
  };

  const handleCommandsSettings = (tab = 0) => {
    navigate(`/CommandsSettings?tab=${tab}`, {
      state: {
        Name: selectedVehData?.vehicleName,
        device: selectedVehData?.deviceType + " " + selectedVehData?.deviceModel,
        deviceImei: selectedVehData?.deviceImei,
        deviceIccid: selectedVehData?.iccid,
        gsmNumbers: selectedVehData?.gsmNumbers,
      },
    });
    setSelectedVeh(null);
    hideMobileMenu();
  }

  const handleTelemetry = () => {
    navigate("/Telemetry", {
      state: {
        Name: selectedVehData?.vehicleName,
        device: selectedVehData?.deviceType,
        deviceModel: selectedVehData?.deviceModel,
        deviceImei: selectedVehData?.deviceImei,
      },
    });
    setSelectedVeh(null);
    hideMobileMenu();
  }

  const handleHistory = () => {
    navigate("/History", {
      state: { vehicleInfo: selectedVehData },
    });
    setSelectedVeh(null);
    hideMobileMenu();
  }

  const hideMobileMenu = () => {
    dispatch({ type: SWITCH_SIDEBAR_VISIBLE, payload: false });
  }
  const handleMobileMenuControlClick = () => {
    dispatch({ type: SWITCH_SIDEBAR_VISIBLE, payload: !showSidebar });
    dispatch({ type: SWITCH_MENU_VISIBLE, payload: false });
  };

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: loadingMsg,
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading, loadingMsg]);


  // ============ socket events ==============

  useEffect(() => {
    sendAddDevicesMsg(devices);
  }, [devices]);

  useEffect(() => {
    function onConnect() {
      if (devices?.length > 0) {
        sendAddDevicesMsg(devices);
      }
    }

    function onDisconnect() {
      sendAddDevicesMsg([]);
    }

    function onUpdateDeviceStatus(data) {
      // console.log(data);
      dispatch({
        type: UPDATE_DEVICE_STATUS,
        payload: data
      });
    }

    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.cmdType == cmdType.EngineOn) {
        setIsLoading(false);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('updateDeviceStatus', onUpdateDeviceStatus);
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('updateDeviceStatus', onUpdateDeviceStatus);
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);


  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>
      {contextHolder}

      {isMobile ? (
        <>
          {!showMenu && (
            <div className={`position-absolute d-flex justify-content-center align-items-center menu-icon w-100 ${showSidebar ? "up-arrow" : "down-arrow"}`}
              onClick={handleMobileMenuControlClick}
            >
              <img className="" src="/assets/arrow.svg" alt="arrow" />
            </div>
          )}
          {showSidebar && (
            <div className="position-absolute w-100 h-100">
              <div className="main-sidebar d-flex flex-column h-100 position-relative">
                <div className="filter d-flex flex-column my-5">
                  <div className="d-flex justify-content-between align-items-center px-5 mx-5 my-1">
                    {connectStatus.map((item) => (
                      <div
                        key={item.id}
                        className={`connected ${connectedTag === item.value && "selected"
                          } d-flex flex-column align-items-center justify-content-center py-1`}
                        style={{ color: item.color }}
                        onClick={() => handleTagClick(item.value, 0)}
                      >
                        <img className="my-1" src={item.icon} alt="none" />
                        <p className="my-0">{item.label}</p>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-between align-items-center px-5 mx-5 my-1">
                    {vehicleTypes.map((item) => (
                      <div
                        key={item.id}
                        className={`connected ${vehicleTypeTag === item.label && "selected"
                          } d-flex flex-column align-items-center justify-content-center py-2`}
                        onClick={() => handleTagClick(item.label, 1)}
                      >
                        <img className="my-1" src={item.icon} alt="none" />
                        <p className="my-0">{item.label}</p>
                      </div>
                    ))}
                    <div className="connected"></div>
                  </div>
                  <div className="sub1-div5 d-flex justify-content-end mt-4">
                    <p className="text-center text-white px-4 py-1 mb-2">
                      Total Vehicles: &nbsp; {filteredVehicles?.length}
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="subsub1-sub2-div5 w-100 d-flex justify-content-evenly position-relative align-items-center">
                      <img
                        className="position-absolute"
                        src="/assets/user2.svg"
                        alt="none"
                      />
                      <Select
                        showSearch
                        allowClear
                        className="w-100 text-center"
                        placeholder="Owner Name"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={owners}
                        value={selectedOwner}
                        onChange={(value) => setSelectedOwner(value)}
                      />
                    </div>
                    <input
                      className="filter-input w-100 mt-3 text-center"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="horizontal-bar m-3"></div>
                <div className="device-container overflow-auto px-1">
                  {filteredVehicles?.map((item, index) => {
                    return (
                      <div
                        key={item.deviceImei}
                        id={"veh-" + item.deviceImei}
                        className={`sub1-div6 d-flex my-2 py-3 ${item.deviceImei === selectedVeh && "selected"}`}
                        onClick={() => setSelectedVeh(item.deviceImei)}
                      >
                        <div className="left-sub1-div6 d-flex flex-column justify-content-between align-items-center px-2 py-4">
                          <img src={`/assets/${item.vehicleType}.svg`} alt="none" className="my-2" />
                          <span
                            className="text-white my-1 text-center w-100"
                            style={{ backgroundColor: handleColor(item.status) }}
                          >
                            {item?.status == 'Idle' ? 'Connected' : item?.status}
                          </span>
                        </div>
                        <div className="vertical-bar"></div>
                        <div className="right-sub1-div6 d-flex flex-column px-2 justify-content-between">
                          <div className="d-flex justify-content-between px-2">
                            <span>{item.vehicleName}</span>
                            <span>
                              <img src={`/assets/${item.ignition == 1 ? 'engine_on' : 'engine_off'}.svg`} alt="" className="me-3" style={{ width: "1.6rem" }} />
                            </span>
                          </div>
                          <div className="right-sub1-div6-sub1 d-flex align-items-center">
                            <img src="/assets/calender.png" alt="none" />
                            <p className="mb-0">
                              {formatDateTime(item?.transferDate)}
                            </p>
                          </div>
                          <div className="right-sub1-div6-sub2 d-flex justify-content-between px-2">
                            <div className="d-flex align-items-center vehicle-status">
                              {item.status == 'Connected' &&
                                <div className="running-bars">
                                  <div className="running-bar1"></div>
                                  <div className="running-bar2"></div>
                                  <div className="running-bar3"></div>
                                  <div className="running-bar4"></div>
                                  <div className="running-bar5"></div>
                                </div>
                              }
                              <img alt="none" className="cursor-pointer" style={{ height: "1.5rem" }}
                                src={`/assets/${item.vehicleType.toLowerCase()}_${item.status.replace(' ', '').toLowerCase()}.svg`}
                              />
                            </div>
                            <Popover trigger="click" placement="bottom"
                              overlayClassName="vehicle-info-wrapper"
                              content={(
                                <div className="vehicle-info">
                                  <div>
                                    <span className="fw-bold">IMEI: </span>
                                    <span>{item.deviceImei}</span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Device: </span>
                                    <span>{item.deviceType} - {item.deviceModel}</span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Coord: </span>
                                    <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(index, e)}>{item.lat}, {item.lng}</span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Address: </span>
                                    <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(index, e)}>{item.address}</span>
                                  </div>
                                </div>
                              )}
                            >
                              <img
                                src="/assets/I.svg" alt="none" className="cursor-pointer"
                                onClick={(e) => { handleShowInfo(index, e); }}
                              />
                            </Popover>
                            <img
                              src="/assets/Pointer.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => {
                                handleLocation(index, e);
                                hideMobileMenu();
                              }}
                            />
                            <img
                              src="/assets/Location.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => {
                                handleNavigation(index, e);
                                hideMobileMenu();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedVeh && (
                  <div
                    ref={modalRef}
                    className="main-car-portal-side-bar position-absolute"
                  >
                    <div className="position-relative">
                      <img
                        className="car-close-portal-side-bar" src="/assets/close.svg" alt="none"
                        onClick={() => setSelectedVeh(null)}
                      />
                      <div className="car-portal-side-bar d-flex justify-content-center align-items-center">
                        <div className="sub1-car-portal-side-bar bg-white">
                          <div className="subsub1-sub1-car-portal-side-bar d-flex px-4 py-3 w-100 align-items-center">
                            <img src="/assets/uber.png" alt="none" />
                            <p className="mb-0 ms-5">UBER</p>
                          </div>
                          <div className="subsub2-sub1-car-portal-side-bar d-flex align-items-center my-1 py-3 px-4">
                            <img src="/assets/Car.svg" alt="none" />
                            <p className="ms-3 mb-0">
                              {selectedVehData?.vehicleName}
                            </p>
                          </div>
                          <div className="subsub3-sub1-car-portal-side-bar my-1 py-2 px-4">
                            <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center">
                              <img src="/assets/arrow.png" alt="none" />
                              <p className="mb-0 ms-4"
                                style={{ color: handleColor(selectedVehData?.status) }}
                              >
                                {selectedVehData?.status == 'Idle' ? 'Connected' : selectedVehData?.status}
                              </p>
                            </div>
                            <div className="sub2-subsub3-sub1-car-portal-side-bar d-flex justify-content-center mt-2">
                              <p className="mb-0">
                                {formatDateTime(selectedVehData?.transferDate)}&nbsp;
                              </p>
                            </div>
                          </div>
                          <div className="subsub3-sub1-car-portal-side-bar my-1 py-2 px-4">
                            <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center">
                              <img src="/assets/gps.svg" alt="none" />
                              <p className="mb-0 ms-4">
                                {selectedVehData?.gpsFixed === 1
                                  ? <div style={{ color: "#28A745" }}>GPS Fixed</div>
                                  : <div style={{ color: "red" }}>GPS Not Fixed</div>}
                              </p>
                            </div>
                            <div className="sub2-subsub3-sub1-car-portal-side-bar d-flex justify-content-center mt-2">
                              <p className="mb-0">
                                {formatDateTime(selectedVehData?.gpsFixedDate)}&nbsp;
                              </p>
                            </div>
                          </div>
                          <div className="subsub5-sub1-car-portal-side-bar">
                            <img src="/assets/eng.jpeg" alt="none" />
                          </div>
                          <div className="subsub6-sub1-car-portal-side-bar d-flex justify-content-center align-items-center">
                            <p className="mb-0 py-1">
                              IMEI: {selectedVehData?.deviceImei}
                            </p>
                          </div>
                        </div>

                        <div className="sub2-car-portal-side-bar h-100 d-flex flex-column justify-content-between align-items-center px-4 py-5">
                          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src="/assets/Pic1.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">
                                {selectedVehData?.speed} KM/HR
                              </p>
                            </div>
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src={"/assets/Key.svg"} alt="none" />
                              <p className="mt-4 mb-0 text-white text-center">
                                Engine <span className={`engine-status ${selectedVehData?.engineStatus == 1 ? 'ON' : 'OFF'}`}>{selectedVehData?.engineStatus == 1 ? 'ON' : 'OFF'}</span>
                              </p>
                            </div>
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src="/assets/Pic3.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">
                                {selectedVehData?.fuel} L
                              </p>
                            </div>
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src="/assets/Pic4.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">
                                {selectedVehData?.temperature} C
                              </p>
                            </div>
                          </div>
                          <div className="horizontal-bar w-100"></div>
                          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer position-relative">
                              <img src="/assets/Pic5.svg" alt="none" />
                              <div style={{ position: "absolute", left: "50%", top: "2rem", display: simState ? "block" : "none" }}>
                                <img src="/assets/close.svg" alt="none" style={{ width: "1.5rem", height: "1.5rem", position: "absolute", right: "-0.7rem", top: "-0.7rem" }} onClick={() => setSimState(false)} />
                                <div style={{ padding: ".8rem", width: "15.5rem", backgroundColor: "white", borderRadius: ".8rem" }}>
                                  <p style={{ fontSize: "1rem", marginBottom: 0 }}><strong>SIM:</strong> {selectedVehData?.mobileNo}</p>
                                  <p style={{ fontSize: "1rem", marginBottom: 0 }}><strong>ICCID:</strong> {selectedVehData?.iccid}</p>
                                </div>
                              </div>
                              <img
                                className="mt-4 mb-0"
                                style={{ width: "1.5rem", height: "1.5rem" }}
                                src="/assets/Group.svg"
                                alt="none"
                                onClick={() => setSimState(true)}
                              />
                            </div>
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src="/assets/Battery.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">
                                {selectedVehData?.battery} %
                              </p>
                            </div>
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src="/assets/Pic7.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">Camera</p>
                            </div>
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center"
                              onClick={handleTelemetry}>
                              <img src="/assets/telemetry.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">Telemetry</p>
                            </div>
                          </div>
                          <div className="horizontal-bar w-100"></div>
                          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
                            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                              <img src={selectedVehData?.dout1 == 1 ? "/assets/Pic2.svg" : "/assets/Pic13.svg"} alt="none" />
                              <label className="car-switch mt-4">
                                <input
                                  type="checkbox"
                                  checked={selectedVehData?.dout1 == 1}
                                  onChange={(e) => {
                                    handleEngine(e, selectedVehData?.deviceImei, selectedVehData?.vehicleName);
                                  }}
                                />
                                <span className="car-slider">
                                  <div className="car-on-off">
                                    <p className="car-p car-p-on">ON</p>
                                    <p className="car-p car-p-off" style={{ marginRight: ".5rem" }}>OFF</p>
                                  </div>
                                </span>
                              </label>
                            </div>
                            <div
                              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
                              onClick={handleHistory}
                            >
                              <img src="/assets/Pic9.svg" alt="none" />
                              <p className="mt-4 mb-0 text-white">History</p>
                            </div>
                            <div
                              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
                              onClick={() => handleCommandsSettings()}
                            >
                              <img src="/assets/commands.svg" alt="none" />
                              <p className="text-center text-white mt-4 mb-0">Commands<br />Setting</p>
                            </div>
                            <div
                              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center"
                              onClick={() => handlUpload()}
                            >
                              <img src={uploadImg} alt="none" />
                              <p
                                className="mb-0 mt-4 text-center"
                                style={{
                                  color:
                                    uploadText === "Upload Config"
                                      ? "white"
                                      : "#F89823",
                                }}
                              >
                                {uploadText}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex flex-column sidebar">
          <div className="logo py-1 d-flex align-items-end ms-4">
            <img
              src="/assets/logo.png"
              alt="none"
              onClick={() => navigate("/Dashboard")}
            />
            <p className="ms-3">TECHWELT</p>
          </div>
          <div className="main-sidebar d-flex flex-column h-100 py-4 position-relative">
            <div className="filter px-2 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center px-3 my-1">
                {connectStatus.map((item) => (
                  <div
                    key={item.id}
                    className={`connected ${connectedTag === item.value && "selected"
                      } d-flex flex-column align-items-center justify-content-center py-1`}
                    style={{ color: item.color }}
                    onClick={() => handleTagClick(item.value, 0)}
                  >
                    <img className="my-1" src={item.icon} alt="none" />
                    <p className="my-0">{item.label}</p>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center px-3 my-1">
                {vehicleTypes.map((item) => (
                  <div
                    key={item.id}
                    className={`connected ${vehicleTypeTag === item.label && "selected"
                      } d-flex flex-column align-items-center justify-content-center py-2`}
                    onClick={() => handleTagClick(item.label, 1)}
                  >
                    <img className="my-1" src={item.icon} alt="none" />
                    <p className="my-0">{item.label}</p>
                  </div>
                ))}
                <div className="connected"></div>
              </div>
              <div className="sub1-div5 d-flex justify-content-end me-3 mt-4">
                <p className="text-center text-white px-4 py-1 mb-2">
                  Total Vehicles: &nbsp; {filteredVehicles?.length}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="subsub1-sub2-div5 w-100 d-flex justify-content-evenly position-relative align-items-center">
                  <img
                    className="position-absolute"
                    src="/assets/user2.svg"
                    alt="none"
                  />
                  <Select
                    showSearch
                    allowClear
                    className="w-100 text-center"
                    placeholder="Owner Name"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={owners}
                    value={selectedOwner}
                    onChange={(value) => setSelectedOwner(value)}
                  />
                </div>
                <input
                  className="filter-input w-100 mt-3 text-center"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
            <div className="horizontal-bar m-3"></div>
            <div className="device-container overflow-auto px-2">
              {filteredVehicles?.map((item, index) => {
                return (
                  <div
                    key={item.deviceImei}
                    id={"veh-" + item.deviceImei}
                    className={`sub1-div6 d-flex my-2 py-2 ${item.deviceImei === selectedVeh && "selected"}`}
                    onClick={() => setSelectedVeh(item.deviceImei)}
                  >
                    <div className="left-sub1-div6 d-flex flex-column justify-content-center align-items-center px-2">
                      <img src={`/assets/${item.vehicleType}.svg`} alt="none" className="my-2" />
                      <span className="text-white my-1 text-center w-100" style={{ backgroundColor: handleColor(item.status) }}>
                        {item?.status == 'Idle' ? 'Connected' : item?.status}
                      </span>
                    </div>
                    <div className="vertical-bar"></div>
                    <div className="right-sub1-div6 d-flex flex-column px-2 justify-content-between">
                      <div className="d-flex justify-content-between px-2">
                        <span>{item.vehicleName}</span>
                        <span>
                          <img src={`/assets/${item.ignition == 1 ? 'engine_on' : 'engine_off'}.svg`} alt="" className="me-3" style={{ width: "1.6rem" }} />
                        </span>
                      </div>
                      <div>
                        <div className="right-sub1-div6-sub1 d-flex align-items-center">
                          <img src="/assets/calender.png" alt="none" />
                          <p className="mb-0">
                            {formatDateTime(item?.transferDate)}
                          </p>
                        </div>
                        <div className="right-sub1-div6-sub2 d-flex justify-content-between px-2">
                          <div className="d-flex align-items-center vehicle-status">
                            {item.status == 'Connected' &&
                              <div className="running-bars">
                                <div className="running-bar1"></div>
                                <div className="running-bar2"></div>
                                <div className="running-bar3"></div>
                                <div className="running-bar4"></div>
                                <div className="running-bar5"></div>
                              </div>
                            }
                            <img alt="none" className="cursor-pointer" style={{ height: "1.5rem" }}
                              src={`/assets/${item.vehicleType.toLowerCase()}_${item.status.replace(' ', '').toLowerCase()}.svg`}
                            />
                          </div>
                          <Popover trigger="click" placement="bottom"
                            overlayClassName="vehicle-info-wrapper"
                            content={(
                              <div className="vehicle-info">
                                <div>
                                  <span className="fw-bold">IMEI: </span>
                                  <span>{item.deviceImei}</span>
                                </div>
                                <div>
                                  <span className="fw-bold">Device: </span>
                                  <span>{item.deviceType} - {item.deviceModel}</span>
                                </div>
                                <div>
                                  <span className="fw-bold">Coord: </span>
                                  <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(index, e)}>{item.lat}, {item.lng}</span>
                                </div>
                                <div>
                                  <span className="fw-bold">Address: </span>
                                  <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(index, e)}>{item.address}</span>
                                </div>
                              </div>
                            )}
                          >
                            <img
                              src="/assets/I.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => { handleShowInfo(index, e); }}
                            />
                          </Popover>
                          <img
                            src="/assets/Pointer.svg" alt="none" className="cursor-pointer"
                            onClick={(e) => { handleLocation(index, e); }}
                          />
                          <img
                            src="/assets/Location.svg" alt="none" className="cursor-pointer"
                            onClick={(e) => { handleNavigation(index, e); }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {
              selectedVeh && (
                <div
                  ref={modalRef}
                  className="main-car-portal-side-bar position-absolute"
                >
                  <div className="position-relative">
                    <img
                      className="car-close-portal-side-bar"
                      src="/assets/close.svg"
                      alt="none"
                      onClick={() => setSelectedVeh(null)}
                    />
                    <div className="car-portal-side-bar d-flex justify-content-center align-items-center">
                      <div className="sub1-car-portal-side-bar bg-white">
                        <div className="subsub1-sub1-car-portal-side-bar d-flex px-4 py-3 w-100 align-items-center">
                          <img src="/assets/uber.png" alt="none" />
                          <p className="mb-0 ms-5">UBER</p>
                        </div>
                        <div className="subsub2-sub1-car-portal-side-bar d-flex align-items-center my-1 py-4 px-4">
                          <img src="/assets/Car.svg" alt="none" />
                          <p className="ms-4 mb-0">
                            {selectedVehData?.vehicleName}
                          </p>
                        </div>
                        <div className="subsub3-sub1-car-portal-side-bar my-1 py-3 px-4">
                          <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center justify-content-center">
                            <img src="/assets/arrow.png" alt="none" />
                            <p className="mb-0 ms-5" style={{ width: "11rem" }}>
                              <div style={{ color: handleColor(selectedVehData?.status) }}>
                                {selectedVehData?.status == 'Idle' ? 'Connected' : selectedVehData?.status}&nbsp;
                              </div>
                              <span>{formatDateTime(selectedVehData?.transferDate)}&nbsp;</span>
                            </p>
                          </div>
                        </div>
                        <div className="subsub3-sub1-car-portal-side-bar my-1 py-3 px-4">
                          <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center justify-content-center">
                            <img src="/assets/gps.svg" alt="none" />
                            <p className="mb-0 ms-5" style={{ width: "11rem" }}>
                              <div>{selectedVehData?.gpsFixed === 1 ?
                                <div style={{ color: "#28A745" }}>GPS Fixed</div>
                                : <div style={{ color: "red" }}>GPS Not Fixed</div>}</div>
                              <span>{formatDateTime(selectedVehData?.gpsFixedDate)}&nbsp;</span>
                            </p>
                          </div>
                        </div>
                        <div className="subsub5-sub1-car-portal-side-bar">
                          <img src={getDeviceImage(selectedVehData?.deviceModel)} alt="none" />
                        </div>
                        <div className="subsub6-sub1-car-portal-side-bar d-flex justify-content-center align-items-center">
                          <p className="mb-0 py-1">
                            IMEI: {selectedVehData?.deviceImei}
                          </p>
                        </div>
                      </div>

                      <div className="sub2-car-portal-side-bar h-100 d-flex flex-column justify-content-between align-items-center px-4 py-5">
                        <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src="/assets/Pic1.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">
                              {selectedVehData?.speed} KM/HR
                            </p>
                          </div>
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src={"/assets/Key.svg"} alt="none" />
                            <p className="mt-4 mb-0 text-white text-center">
                              Engine <span className={`engine-status ${selectedVehData?.engineStatus == 1 ? 'ON' : 'OFF'}`}>{selectedVehData?.engineStatus == 1 ? 'ON' : 'OFF'}</span>
                            </p>
                          </div>
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src="/assets/Pic3.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">
                              {selectedVehData?.fuel} L
                            </p>
                          </div>
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src="/assets/Pic4.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">
                              {selectedVehData?.temperature} C
                            </p>
                          </div>
                        </div>
                        <div className="horizontal-bar w-100"></div>
                        <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer position-relative">
                            <img src="/assets/Pic5.svg" alt="none" />
                            <div style={{ position: "absolute", left: "50%", top: "2rem", display: simState ? "block" : "none" }}>
                              <img src="/assets/close.svg" alt="none" style={{ width: "1.5rem", height: "1.5rem", position: "absolute", right: "-0.7rem", top: "-0.7rem" }} onClick={() => setSimState(false)} />
                              <div style={{ padding: ".8rem", width: "15.5rem", backgroundColor: "white", borderRadius: ".8rem" }}>
                                <p style={{ fontSize: "1rem", marginBottom: 0 }}><strong>SIM:</strong> {selectedVehData?.mobileNo}</p>
                                <p style={{ fontSize: "1rem", marginBottom: 0 }}><strong>ICCID:</strong> {selectedVehData?.iccid}</p>
                              </div>
                            </div>
                            <img
                              className="mt-4 mb-0"
                              style={{ width: "1.5rem", height: "1.5rem" }}
                              src="/assets/Group.svg"
                              alt="none"
                              onClick={() => setSimState(true)}
                            />
                          </div>
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src="/assets/Battery.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">
                              {selectedVehData?.battery} %
                            </p>
                          </div>
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src="/assets/Pic7.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">Camera</p>
                          </div>
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
                            onClick={handleTelemetry}>
                            <img src="/assets/telemetry.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">Telemetry</p>
                          </div>
                        </div>
                        <div className="horizontal-bar w-100"></div>
                        <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
                          <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
                            <img src={selectedVehData?.dout1 == 1 ? "/assets/Pic2.svg" : "/assets/Pic13.svg"} alt="none" />
                            <label className="car-switch mt-4">
                              <input
                                type="checkbox"
                                checked={selectedVehData?.dout1 == 1}
                                onChange={(e) => {
                                  handleEngine(e, selectedVehData?.deviceImei, selectedVehData?.vehicleName);
                                }}
                              />
                              <span className="car-slider">
                                <div className="car-on-off">
                                  <p className="car-p car-p-on">ON</p>
                                  <p className="car-p car-p-off" style={{ marginRight: ".5rem" }}>OFF</p>
                                </div>
                              </span>
                            </label>
                          </div>
                          <div
                            className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
                            onClick={handleHistory}
                          >
                            <img src="/assets/Pic9.svg" alt="none" />
                            <p className="mt-4 mb-0 text-white">History</p>
                          </div>
                          <div
                            className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
                            onClick={() => handleCommandsSettings()}
                          >
                            <img src="/assets/commands.svg" alt="none" />
                            <p className="text-center text-white mt-4 mb-0">Commands<br />Setting</p>
                          </div>
                          <div
                            className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center"
                            onClick={() => handlUpload()}
                          >
                            <img src={uploadImg} alt="none" />
                            <p
                              className="mb-0 mt-4 text-center"
                              style={{
                                color:
                                  uploadText === "Upload Config"
                                    ? "white"
                                    : "#F89823",
                              }}
                            >
                              {uploadText}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              )
            }
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
